@menuBg: @body-bg;
@borderColor: @color-border;
@mmFontfamily:@font-family-sans-serif;

@mmIndent: 10px;

// Level 1
@level-1-color: @color-text-dark;
@level-1-bg-color: transparent;
@level-1-selected-color: @color-first;
@level-1-selected-bg-color: none;
@level-1-text-transform: uppercase;

// Level 2
@level-2-color: @color-text;
@level-2-bg-color: #f5f5f5;
@level-2-selected-color: @color-first;
@level-2-selected-bg-color: none;
@level-2-text-transform: none;

//Level 3
@level-3-color: @color-text;
@level-3-bg-color: #eeeeee;

//Level 4
@level-4-color: @color-text;
@level-4-bg-color: #ccc;

#page {
	&:after {
		content:'';
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		max-width:0;
		opacity: 0;
		z-index: @zindex-header - 1;
		background: rgba(0,0,0, 0.3);
		transition: .4s ease all;
	}

	.mainmenu-open & {
		&:after {
			opacity: 1;
			max-width: 9999em;
		}
	}
}

body {
	&.mainmenu-open {
		overflow:hidden;
	}
}

#mainmenu {
	@media (max-width:@screen-sm-max) and (min-width:@screen-xs-min) {
		max-width: 320px;
	}
	@media (max-width:@screen-sm-max) {
		position: fixed;
		top: @header-height-mobile;
		right: 0;
		bottom: 0;
		z-index: @zindex-header + 1;
		width: 100%;
		background: @menuBg;
		overflow-y: auto;
		transform:translateX(100%);
		transition: .4s ease all;
		padding-top: 80px;

		body.mainmenu-open & {
			transform:translateX(0);
		}
		.loggedIn & {
			margin-top: 31px;
		}

		li {
			position: relative;

			&:not(.down) {
				> ul {
					display:none;
				}
			}

			a:hover,
			&[class*="selectedLevel"] > a {
				color: @color-first;
			}
			&[class*="selectedLevel"] > a {
				font-weight:bold;
			}
		}

		a {
			display:block;
			font-family:@mmFontfamily;
		}

		.mmLevel1 {
			display:block;
			margin: 0 15px;
			border-bottom: 1px solid @borderColor;
		}

		.mmLevel2 {
			position: relative;
			overflow: hidden;
			border-top: 1px solid @borderColor;
			background: @level-2-bg-color;
			margin-bottom:-1px;
			left: auto!important;
			max-height:none!important;

			> li.down {
				background:@level-2-selected-bg-color;
			}
		}

		.mmLevel3 {
			background:@level-3-bg-color;
		}

		.mmLevel4 {
			background:@level-4-bg-color;
		}

		.level1 {
			display:block;
			width:100%;
			position: relative;

			&:not(:first-child) {
				border-top:1px solid @borderColor;
			}
			> a {
				text-transform: @level-1-text-transform;
				color:@level-1-color;
				font-size:18px;
				padding:9px 0 10px;
			}

			> .expand {
				padding:10px 15px;
			}
		}

		.level2 {
			font-size:16px;

			> a {
				color: @level-2-color;
				font-weight:400;
				padding: 8px 10px 7px 6px + @mmIndent;
			}

			> .expand {
				padding: 6px 15px;

				i {
					transition: none;

					&:before {
						content:'+';
					}
				}
			}

			&.down > .expand {
				i {
					transform: translate3d(-3px, -2px, 0);

					&:before {
						content: '-';
					}
				}
			}
		}

		.level3 {
			a {
				color: @level-3-color;
				padding:5px 15px 5px 15px + @mmIndent;
			}

			.expand {
				padding: 5px 15px;
			}
		}

		.level4 {
			a {
				color: @level-4-color;
			}
			> a {
				padding-left: 15px + @mmIndent * 2;
			}
		}

		.level5 {
			> a {
				padding-left: 15px + @mmIndent * 3;
			}
		}

		.mmLevel6 {
			display:none;
		}

		.selectedLevel1  {
			.expand  {
				i {
					color: @level-1-selected-color;
				}
			}

			> a {
				background:@level-1-selected-bg-color;
				color: @level-1-selected-color;
			}
		}

		.expand {
			display:block!important;
			position:absolute;
			top:0;
			right:0;
			font-size: 24px!important;
			line-height: 1;
			overflow:hidden;
			cursor: pointer;
			color: @color-first;

			i {
				transition:transform .4s ease;
			}
		}

		.level1.down > .expand i {
			transform:scale(-1);
		}
	}
}
