.frontpage {
	background:@color-white;

	.box {
		&.srTitle {
			.boxTitle {
				* {
					&:extend(.sr-only);
				}
			}
		}
	}

	#main {
		.box {
			.boxTitle {
				h2 {
					font-size:22px;
					color:@color-black;
					text-transform:uppercase;
					margin-bottom: 60px;

					@media (max-width:@screen-sm-max) {
						font-size: 18px;
						margin-bottom:45px;
					}

					&:after {
						content: '';
						display: block;
						max-width: 41px;
						border-bottom: 2px solid @color-first;
						margin-top: 17px;
					}
				}
			}
		}
	}

	.btn-primary {
		display:inline-block;
		background:none;
		border:1px solid @color-first;
		background:@color-first;
		font-weight:bold;
		padding: 13px 20px;
		font-size: 16px;
		text-transform:uppercase;

		&:hover {
			background:@color-white;
			color:@color-first;
		}
	}
}

#links {
	ul {
		.make-row();
	}
	li {
		.make-xs-column(12);

		@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
			width:50%;

			&:last-child:nth-child(odd) {
				width:100%;
			}
		}
		text-align:center;
		margin-bottom:20px;

		> a {
			display: flex;
			border:1px solid #e1e1e1;
			min-height: 128px;
			align-items: center;
			padding: 10px 15px;
		}

		img {
			margin-left:auto;
			margin-right:auto;
		}

		.jip {
			background: none;
			padding: 0;
			right: 25px;
		}
	}
}

#below {
	margin-top: 15px;

	> .box {
		border-top: 1px solid #e7e7e7;
		padding:94px 0 43px;

		@media (max-width:@screen-xs-max) {
			padding: 52px 0 20px;
		}

		&:nth-child(odd) {
			background: #f5f5f5;
		}

		> div {
			&:extend(.contentWrap);
		}
	}
}

#promo {
	.make-row();

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	li {
		.make-xs-column(12);
		text-align:center;
		max-width: 400px;
		margin-bottom: 30px;

		.media {
			font-size:103px;
			color:@color-first;
			line-height: 1;
			overflow:hidden;
			margin-bottom: 24px;
		}

		img {
			transition: transform .4s ease;
		}

		h3 {
			font-size:22px;
			font-weight:bold;
			margin: 0 0 16px;
			color:@color-black;
			transition:color .4s ease;
		}

		.text {
			color:@color-text-light;
			line-height:1.3334;
		}

		a.more {
			margin-top: 26px;
		}

		.content {
			position: relative;

			> a {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			&:hover {
				.media img {
					transform: perspective(1px) scale(1.04);
				}

				h3 {
					color:@color-first;
				}
			}
		}

		.info {
			position: relative;
			padding-bottom: 13px;

			&:after {
				content:'';
				position: absolute;
				left:0;
				right:0;
				bottom: 0;
				max-width:41px;
				height:2px;
				background:@color-first;
				margin: 0 auto;
			}
		}
	}
}

#focus {
	margin-top: 31px;

	.mceAlignRight {
		@media (min-width:@screen-md-min) {
			margin-top: -55px;
		}
	}

	li {
		.clearfix();

		h2 {
			font-size:40px;
			line-height: 1.175;
			color:@color-third;
			margin-bottom: 28px;

			@media (max-width:@screen-xs-max) {
				font-size:30px;
			}

			&:after {
				&:extend(.frontpage #main .box .boxTitle h2:after);
				max-width: 80px;
				margin-top:24px;
			}
		}

		.text {
			font-size: 22px;
			line-height: 1.364;
			color:@color-text-light;

			a.btn {
				margin-top: 35px;
			}
		}
	}
}
