#tube {
	.player {
		margin-bottom: 20px;
	}
	.description {
		margin-bottom:20px;
	}

	.entries {
		.make-row();
		display:flex;
		flex-wrap:wrap;

		.videoThumb {
			.make-xs-column(12);
			.make-sm-column(4);
			.make-md-column(3);
			margin-bottom: 30px;

			body:not(.hideright) & {
				.make-md-column(4);
				.make-lg-column(3);
			}

			img {
				width: 100%;
				padding-bottom: 10px;
			}
			h3 {
				font-size: 18px;
				font-weight: bold;
				margin:0;

				a {
					color:@color-text;

					&:hover {
						color:@color-first;
					}
				}
			}
		}
	}

	.entryInfo {
		&:extend(#news .entryInfo);
	}

	.related {
		h2 {
			border-top: 1px solid @color-border;
			padding: 20px 0 10px;
		}
	}
}

