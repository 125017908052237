#sidebar {
	@media (min-width:@screen-md-min) {
		margin-top: 93px;

		.frontpage & {
			margin-top: 10px;
		}
	}
	@media (max-width:@screen-sm-max) {
		margin-top:40px;
	}

	.box {
		@media (min-width:@screen-md-min) {
			float: right;
			width: 100%;
			max-width: 305px;
		}

		a {
			color:@color-text-dark;

			&:hover {
				color:@color-first;
			}
		}

		.boxTitle {
			h2 {
				font-size: 14px;
				font-weight: 600;
				text-transform: uppercase;
				color:@color-first;
				padding-bottom: 9px;
				border-bottom:1px solid;
				margin-bottom: 18px;
			}
		}

		&:not(:last-child) {
			margin-bottom:40px;
		}

		&.gray {
			background:#e8e8e8;
			padding: 18px 0 36px;

			.boxTitle {
				margin-bottom: 20px;

				&:after {
					content:'';
					display: block;
					max-width: 63px;
					border-bottom: 1px solid @color-first;
				}

				h2  {
					border-bottom:0;
					margin-bottom:0;
				}
			}

			.boxTitle h2,
			.boxText {
				padding-left:20px;
				padding-right:20px;
			}
		}

		&.box_sitemap {
			.hidden-sm;
			.hidden-xs;

			a {
				display:block;
				font-weight:300;
			}

			li[class*="selectedLevel"] > a {
				color:@color-first;
			}

			.level2 {

				> a {
					padding: 6px 0;
				}

				&.selectedLevel2 > a:first-child {
					position: relative;
					font-weight:bold;

					&:before {
						content:'';
						position: absolute;
						width: 3px;
						height: 24px;
						background: @color-first;
						left: -52px;
						top: 9px;

						@media (max-width:@screen-md-max) and (min-width:@screen-md-min) {
							left:-2px;
						}
					}
				}

				&.children {
					position: relative;

					.expand {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						height: 39px;
						width: 29px;
						line-height: 28px;
						text-align: center;
						color:@color-first;
						cursor:pointer;

						&:before {
							content:'+';
							float:right;
							font-size: 22px;
						}
					}

					&.down {
						.expand {
							&:before {
								content:'-';
								transform: translate3d(-2px,-1px,0px);
							}
						}

						.mmLevel3 {
							display:block!important;
						}
					}

					&:not(.down) {
						.mmLevel3 {
							display:none!important;
						}
					}
				}
			}

			.mmLevel3 {
				background:#f5f5f5;
				padding: 15px;

				a {
					padding:1px 0;
					color:@color-text-light;
				}
			}
		}

		&.box_newsarchive {
			dd, dt {
				line-height:1.3334;
			}

			dl {
				padding-top: 8px;
			}

			dd {
				font-weight:300;
				margin-bottom: 24px;
			}
		}
	}
}
