#searchToggle {
	.hidden-sm;
	.hidden-xs;
	position: relative;
	border: 0;
	background: none;
	font-size:20px;
	color:currentColor;
	padding-top: 7px;
	display: inline-block;
	text-align:center;
	transition:color .4s ease;

	@media (min-width:@screen-md-min) {
		width:50px;
	}

	.search-open &,
	.search-closing & {
		color:@color-first;
	}

	i {
		transition:transform .4s ease;

		.search-open & {
			transform:perspective(1px) rotate(360deg);
		}
	}
}

#searchBox {
	@media (min-width:@screen-md-min) {
		position: absolute;
		top: 0;
		right: -15px;

		form {
			position:absolute;
			right: 42px;
			top: 0;
			overflow:hidden;
			transition:width .4s ease, opacity .1s ease;

			body:not(.search-open) & {
				width:0!important;
			}
			body:not(.search-open):not(.search-closing) & {
				opacity:0;
			}

			&:before {
				content:'';
				position: absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
				background: @color-white;
				border: 1px solid @color-first;
			}

			.search-open & {
				width:280px;
			}
		}

		.elm_text {
			position: relative;
			float:right;
			padding: 1px;
		}

		.elm {
			margin:0;
		}

		.lmnt {
			display:block;
		}

		#q {
			width:100%;
			border: 0;
			outline:0;
			.placeholder(@color-text-light);
			color:@color-text-light;
			height:auto;
			box-shadow: none;
			padding: 8px 50px 6px 10px;
			border-radius:0;
		}

		.elm_submit {
			position: absolute;
			top: 1px;
			bottom: 1px;
			right: 1px;
			width: 48px;
			text-align: center;
			line-height: 43px;

			.fa-before(@fa-var-search);
			&:before {
				font-size:20px;
				color:@color-first;
				transition:color .4s ease;
			}

			&:hover:before {
				color:@color-second;
			}
		}

		#searchSubmit {
			position: absolute;
			right: 0;
			top: 0;
			width: 42px;
			height: 42px;
			text-indent: 99px;
			overflow: hidden;
			background: none;
			border: 0;
			box-shadow: none;
		}
	}

	@media (max-width:@screen-sm-max) {
		position: absolute;
		top: 0;
		width: 100%;
		padding: 9px 15px;
		background: #f0f0f0;
		border-bottom: 1px solid #ccc;

		form {
			position: relative;
			width:100%!important;
			margin-top: 10px;
			transition:opacity .4s ease;
		}

		#q {
			width: 100%;
			min-width: 0!important;
			padding-right: 40px;
			border-radius:0;
			box-shadow:none;
		}

		#elm_submit {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			height: 100%;

			.fa-before(@fa-var-search);
			&:before {
				display:block;
				font-size: 20px;
				color: @color-black;
				padding:11px;
				transition: color .4s ease;
			}
		}
		#searchSubmit {
			position: absolute;
			width: 100%;
			height: 100%;
			border: 0;
			padding: 0;
			margin: 0;
			cursor: pointer;
			text-indent: -9999em;
			font-size: 0;
			background: none;
			top: 0;
			right: 0;
			line-height: 0;
			color:@color-first;
		}
	}
}
