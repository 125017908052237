html {
	height:101%;
}

body {
	-webkit-font-smoothing:antialiased;
	min-width:305px;
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	flex-direction: column;

	&#tinymce, &.mceContentBody {
		display:block;
		flex-direction: initial;
	}
}

.mm-page {
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	flex-direction: column;
}

#page {
	flex: 1 0 auto;

	//IE fix
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		min-height:92vh;
	}
}

#footerInfo {
	flex-shrink: 0;

	//IE fix
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		min-height:8vh;
	}
}

#pageContainer {
	position:relative;

	// Move content down so the mobile header does not block it
	@media only screen and (max-width:@screen-sm-max) {
		padding-top:@header-height-mobile;
	}
}

#contentContainer {
	padding-bottom:50px;

	@media (max-width:@screen-sm-max) {
		body:not(.frontpage) & {
			padding-top: 25px;
		}
	}

	> .contentWrap {
		.clearfix();

		> .row {
			@media (min-width:@screen-md-min) {
				display: flex;
			}
		}
	}
}

#main {
	.make-md-column(9);

	body.hideright & {
		.make-md-column(12);
	}

	> .box {
		position: relative;

		&:after {
			@media (min-width: @screen-lg-min) {
				content: '';
				position: absolute;
				width: 1px;
				right: 21px;
				top: -10px;
				bottom: -14px;
				background: @color-border;

				.frontpage & {
					background:#e5e5e5;
					top: 18px;
					right: 32px;
					bottom: -1px;
				}

				.hideright & {
					display:none;
				}
			}
			@media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
				right: 0;
			}
		}
		.boxText {
			@media (min-width: @screen-lg-min) {
				padding-right: 100px;
			}
			@media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
				padding-right: 32px;
			}

			body.hideright & {
				padding-right:0;
			}
		}
	}
}

#sidebar {
	.make-md-column(3);
	.noprint;

	body.hideright & {
		&:extend(.hidden);
	}
}
