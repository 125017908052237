table {
	tr {
		vertical-align: top;
	}

	&.table1,
	&.table2 {
		margin-top:30px;
		margin-bottom:30px;
		width: 100%;
		font-size: 16px;
		font-weight: 400;

		th {
			font-weight:@font-weight-bold;
			background-color:@color-gray;
		}
		thead {
			border-top:1px solid @color-border-light;
			border-bottom:1px solid @color-border-light;

			p {
				margin-bottom:0;
			}
		}

		th,
		td {
			padding: 4px 8px 5px;

			* {
				margin-bottom:0;

				+* {
					margin-top:10px;
				}
			}
		}

		tr:hover:not(:first-of-type) {
			background-color: @color-border;
		}
	}

	&.table1 {
		tr {
			border-bottom: 1px solid @color-border-light;

			&:nth-of-type(even) {
				background-color: @color-gray;
				border-top: 1px solid @color-gray;
			}
		}

		th {
			background-color: @color-first;
			color: #fff;
		}
	}
}

.table-responsive {
	clear:both;
	margin-top:30px;

	@media (max-width:@screen-xxs-max) {
		padding:10px;
	}

	table {
		margin-top:0;
	}
}
