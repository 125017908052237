@color-text-light: #999;

#news {
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.entries {
		.entry {
			br.clear {
				display: none;
			}
		}
	}

	.entry {
		.clearfix();
		padding-bottom: @line-height-computed;
		border-bottom: 1px solid #ccc;
		margin-bottom: @line-height-computed;
	}

	.entryContent {
		clear: none;
	}

	.entryInfo {
		font-size: 14px;
		color: @color-text-light;
		margin-bottom: 5px;
	}

	.entryImage {
		&.thumb {
			float: left;
			width: 25%;
			margin-right: @line-height-computed;

			@media (max-width: @screen-xs) {
				width: 100%;
				margin: 0;
				margin-bottom: 10px;
			}
		}

		&.medium {
			float: right;
			width: 50%;
			margin-left: @line-height-computed;
			margin-bottom: @line-height-computed;

			@media (max-width: @screen-xs-max) {
				width: 100%;
				margin: 0;
			}
		}
	}

	.entryFooter {
		clear: both;
		width: 100%;
		margin-top: @line-height-computed;
		border-top: 1px solid #ccc;
		padding-top: @line-height-computed;
	}

	.more {
		display: block;
		white-space: nowrap;
		margin-top: 10px;
	}

	.back {
		float: left;
	}

	.more, .back {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
	}

	.social {
		float: right;
	}

	.preview {
		img {
			width: 100%;
		}
	}

	.news_autopublish,
	.news_unpublished,
	.news_calendaronly,
	.news_sticky {
		padding: 0 5px;
		margin: 0 5px 0 0;
		background: #33a;
	}
	.news_unpublished {
		background: #a33;
	}
	.news_calendaronly {
		background: #aa3;
	}
	.news_sticky {
		background: #3aa;
	}
	.albumImages {
		list-style: none;
		overflow: hidden;
		margin: 30px 0;
		padding: 0;

		li {
			float: left;
			margin: 0 5px 5px 0;

			img {
				width: 170px;
			}
		}
	}
}

.news_calendar {
	width: 100%;

	.calendar-day-red {
		color: #f00;
	}

	.calendar-day-today {
		font-weight: 700;
		background-color: #f3f3f6;
	}

	td,
	.calendar-month {
		text-align: center
	}

	.calendar-month {
		border-bottom: 1px solid #eee;
	}

	.calendar-day,
	.calendar-day-red,
	.calendar-day-today {
		border: 1px solid #eee;
	}
}

#eventsBox .entryInfo {
	margin-bottom: 0;
}

#eventsBox .entryTitle {
	float: none;
	margin-bottom: 0.5em;
}

/*
#commentlist .entryInfo {
	float:left;
	margin:0 4px 0 0;
}
#commentlist .entry {
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
	padding:8px;
	margin-bottom:1em;
}
#commentlist .altRow {
	background:#fafbfc
}
*/

.calSummaryNav {
	margin-bottom: 1.5em;
}

.calSummaryNav li {
	display: inline;
	margin-right: 18px;
}

#newstabs textarea {
	width: 100%;
	min-height: 100px;
}

#newstabs_stillingar {
	.options {
		input {
			display: block;
			float: left;
		}

		label {
			display: block;
		}
	}

	.autodate {
		label {
			margin-top: 10px;
		}
	}
}