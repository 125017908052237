@banner-desktop-height: 590px;
@banner-tablet-height: 363px;
@banner-mobile-height: 486px;

#owl-hero, #owl-hero .item, #owl-hero .image, #owl-hero .contentWrap {
	@media (min-width:@screen-md-min) {
		height:@banner-desktop-height;
	}

	@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
		height:@banner-tablet-height;
	}
	@media (max-width:@screen-xs-max) {
		height:@banner-mobile-height;
	}
}
#owl-hero {
	position: relative;
	background: @color-white;
	.noprint;

	.box:not(.full) & {
		margin: 0 -15px;
	}

	.contentWrap {
		padding:0;
		max-width:1470px;

		.full & {
			max-width:none;
		}
	}

	.items {
		width:100%;
	}

	.item {
		position:relative;

		&:nth-child(n+2) {
			display:none;
		}

		.contentWrap {
			@media (min-width:@screen-md-min) {
				padding: 70px 55px 60px;
			}
			@media (max-width:@screen-sm-max) {
				padding: 30px 15px 30px;
			}
			@media (max-width:@screen-xs-max) {
				padding-right:20px;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-align:center;
		}

		.image {
			position:absolute;
			left:0;
			right:0;
			top:0;
			bottom:0;
			background-repeat:no-repeat;
			background-position:center center;
			background-size:cover;

			&:after {
				content:'';
				position: absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
				background:fade(@color-black, 30%);
			}
		}

		.content {
			position:absolute;
		}
	}

	.jip {
		position: absolute;
		top: 10px;
		right: 10px;
		background: none;
		padding: 0;
	}

	.text {
		font-size: 22px;
		color:@color-white;
		max-width: 720px;
		line-height: 1.273;

		@media (max-width:@screen-sm-max) {
			font-size:16px;
		}

		h2 {
			position:relative;
			font-size:49px;
			font-weight:bold;
			line-height:1.25;
			margin:0 0 21px;
			color:@color-white;
			text-transform:uppercase;

			&:after {
				content:'';
				display:block;
				width:100%;
				max-width:221px;
				margin: 23px auto 34px;
				border-bottom:2px solid;
			}

			a {
				color:@color-white;
			}

			@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
				font-size:26px;
			}

			@media (max-width:@screen-xs-max) {
				font-size:24px;
			}
		}

		a.more,
		a.btn-primary {
			margin-top: 35px;
			display:inline-block;
			border:1px solid @color-white;
			text-transform:uppercase;
			color:@color-white;
			font-size:16px;
			font-weight:600;
			padding: 12px 22px;
			transition:background .4s ease, color .4s ease;

			@media (max-width:@screen-sm-max) {
				margin-top: 20px;
			}

			&:hover {
				background:@color-white;
				color:@color-first;
			}
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 15px;
		right: 26px;

		.owl-dot {
			position: relative;
			display: inline-block;
			width: 16px;
			height: 16px;
			border: 3px solid #939495;
			background: #939495;
			border-radius:50%;
			margin: 0 2px;

			&.active {
				background:@color-first;
			}

			span {
				display:none;
			}
		}
	}
}
