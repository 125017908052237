@media (min-width:@screen-sm-min) {
	.mceAlignRight {
		.pull-right;
		margin: 0 0 @line-height-computed @grid-gutter-width;
		clear: right;
	}
	.mceAlignLeft {
		.pull-left;
		margin: 0 @grid-gutter-width @line-height-computed 0;
		clear: left;
	}
}


@media (max-width:@screen-xs-max) {
	.mceAlignRight,
	.mceAlignLeft {
		display:block;
		margin: 0 0 15px;
	}
}
