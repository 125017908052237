.noscreen, .hidden, .hide {
	display: none;
}

.entries{
	margin:0;
}
.entry{
	list-style:none;
	margin-top:0.5em;
}
.entryTitle{
	margin:0 4px 0 0;
}
.entryContent{
	clear:left;
}
.entryFooter{
	clear:both;
}
.entryInfo{
	font-size:10px;
	clear:left;
}
.entries .entryInfo {
	clear: none;
}
.entryImage{
	float:left;
}
.entryImage img {
	border:0;
}
.entryImageText{
	max-width: 100%;
	padding:0 1em 1em;
	font-style:italic;
	text-align:center;
}
.entryResources li {
	list-style:none;
}
.entryLabel{
	font-weight:700;
}
.entryValue{
	margin-bottom:1em;
}

/** MODULE SPECIFIC **/

/*adverts*/
#adman .preview, #adman #tab1 > img {
	float:right;
	margin:10px;
	max-width:320px;
	max-height:320px;
	height: auto;
}
#adman .preview img{
	max-width: 100%!important;
	height: auto!important;
	max-height: 190px;
}

.strevda ul {
	list-style:none;
	margin:0;
}
.strevda li {
	position:relative;
}
.strevda .typetag {
	font-size:9px;
	text-transform: uppercase;
	background:#eee;
	background:rgba(0, 0, 0, 0.1);
	color:#666;
	padding:1px 4px;

	position:absolute;
	top:-14px;
	left:0;
}
.strevda .typetag.type2 {
	background:#ff9;
	background:rgba(255, 255, 120, 0.4);
	color:#990;
}

/*fileman*/
#filemanBrowser, #filemanToolbar {
	font-size:0.917em;
}
#filemanBrowser .mimeicon {
	vertical-align:middle;
}
#filemanBrowser .folder {
	font-weight:700;
}
#filemanToolbar {
	padding:6px 8px 10px;
	height:14px;
	background:url('/_moya/images/admin/admin_bg_white.png') repeat-x 0 0;
	border:1px solid #ddd;
}
#filemanToolbar a{
	color:#333;
	text-decoration:none;
	margin-right:8px;
}
#filemanToolbar a:hover {
	text-decoration:underline;
}
#filemanToolbar input {
	float:right;
	width:210px;
	margin-top:-2px;
}
#filemanToolbar .icon {
	margin-right:4px;
}

/** PRE-DEFINED **/

.thin {	width:1%; }
.column{ float:left; }
.w50{ width:49%; }
.w33{ width:33%; }

.menu, .toolbar{
	margin:0;
	list-style:none outside;
}

.horizontal li, .toolbar li{
	display:inline;
	margin-right:1em;
}

.required {
	color:#822;
}
.required:after {
	content: " *";
}

.altRow2{
	background:#f7f7f7;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
}

/** SOON TO DIE **/

.listAll{
	width:100%;
}
.listAll td, .listAll th{
	padding:4px;
}
.listAll th {
	text-align:left;
}
.toolCol {
	text-align:right;
	width:1%;
	white-space:nowrap;
}
.rusl, #rusl {
	display:none;
}
#captcha, .captchaImg {
	display:block;
}
label.inline{
	float:left;
	display:block;
	width:120px;
	clear:left;
}
.categories{
	list-style:none;
	display:inline;
	margin:0;
}
/*loading overlay*/
.waiting {
	position: relative;
}
.waiting .overlay {
	text-align: center;
	width:100%;
	height:100%;
	z-index: 100;
	background: #fff url(/_w/elrte/images/loading.gif) no-repeat 50% 200px;
	position: absolute;
	top:0;left:0;
	visibility: visible;
}
.waiting li {
	list-style: none;
}

#userAttributes {
	width:50%;
}
#userAttributes td {
	padding:4px;
}
#userAttributes input {
	width:100%;
}

/*
To fix elfinder opened from tinymce.
JQuery UI should now default to 200020 as base zIndex.
TinyMCE InlinePopupus plugin uses 300000 as base zIndex.
So we force elFinder to base at 400010.
This way we get the correct stack order without using jquery dialog stack stuff.
*/
.ui-front {
	z-index: 200020 !important;
}
.ui-widget-overlay.ui-front {
	z-index: 200010 !important;
}
.ui-front.el-finder-dialog {
	z-index: 400010 !important;
}
