#banner {
	.noprint;

	.contentWrap {
		max-width: @site-width + @grid-gutter-width + 215px;

		@media (max-width: @screen-sm-max) {
			padding:0;
		}
	}
}

#custom-banner {
	margin-bottom:57px;

	@media (max-width: @screen-xs-max) {
		margin-bottom: 42px;
		background:#f2f2f2;

		.item {
			overflow:hidden;
		}
	}

	.image {
		&.padding {
			position: relative;

			img {
				@media (min-width:@screen-sm-min) {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}
			}
		}

		@media (max-width:@screen-xs-max) {
			margin: 0 -123px;
			padding-bottom: 0!important;
		}
	}

	.text {
		text-shadow: 0 2px 0 rgba(0,0,0,.15);

		@media (min-width:@screen-sm-min) {
			position: absolute;
			bottom: 39px;
			left: 0;
			width: 100%;
			padding-left: 51px;
			color: @color-white;
		}
		@media (min-width:@screen-md-min) {
			max-width: 60%;
		}
		@media (max-width:@screen-xs-max) {
			padding: 22px 15px;
		}

		* {
			color:inherit;
		}

		h2 {
			font-size:53px;
			margin-bottom: 25px;

			@media (max-width:@screen-sm-max) {
				font-size: 42px;
			}
			@media (max-width:@screen-xs-max) {
				font-size: 24px;
				line-height: 1.25;
				margin-bottom: 20px;

				br {
					display:none;
				}
			}
		}

		.btn {
			background:@color-first;
			color:@color-white;
			font-size: 18px;
			padding: 9px 19px 11px;
			text-shadow:none;

			@media (max-width:@screen-xs-max) {
				font-size: 16px;
				padding: 7px 19px 9px;
			}

			&:hover {
				background:@color-white;
				color:@color-first;
			}
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 13px;
		right: 21px;

		.owl-dot {
			position: relative;
			display: inline-block;
			width: 15px;
			height: 15px;
			border: 1px solid #e1e1e1;
			background: @color-white;
			margin-left: 7px;

			&.active {
				background:@color-first;
			}

			span {
				display:none;
			}
		}
	}

	.jip {
		position: absolute;
		top: 10px;
		right: 10px;
		background: none;
		padding: 0;
	}
}
