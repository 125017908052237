@media (min-width:@screen-md-min) {
	#mainmenu {
		.mmLevel1 {
			.level1 {
				position:relative;

				&:not(.open) {
					>.mmLevel2 {
						max-height:0!important;
					}
				}

				.mmLevel2 {
					display:block;
					position:absolute;
					top: 49px;
					left: -1px;
					min-width: 225px;
					background: #fff;
					z-index: 99;
					text-align:left;
					line-height:normal;
					.transition(0.4s);
					opacity: 0;
					overflow:hidden;
					border:1px solid #e7e7e7;
					box-shadow: 0 8px 9px 0 rgba(0,0,0,.4);

					li {
						&:first-of-type {
							position: relative;
							border-top: 2px solid @color-first;
							padding-top:18px;
						}
						&:last-of-type {
							a {
								padding-bottom: 28px;
							}
						}
						a {
							display:block;
							padding: 3px 28px;
							white-space: nowrap;
							font-size: 18px;
							text-transform: none;
							//text-align: left;
							color:@color-text-dark;
							&:hover {
								color: @link-color;
							}
							span {
								display: block;
								padding: 4px 0 4px;
							}
						}

						&.selectedLevel2 a {
							position: relative;
							color: @link-color;
						}
						ul {
							display:none;
						}
					}
				}
				&.open {
					> a {
						z-index: 99;
						&:after {
							.opacity(1);
						}
						&:after {
							bottom:1px;
						}
					}

					.mmLevel2 {
						opacity: 1;
					}
				}
			}
		}
	}
}
