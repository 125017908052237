a {
	transition: color .4s ease;
}

img.responsive {
	.img-responsive();

	visibility:hidden;

	&.loaded {
		visibility:visible;
	}
}

img {
	max-width:100%;
	height:auto;
}

.jip img,
.toolCol img,
img.icon {
	max-width:none;
}

.entry {
	margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

#main {
	h1 {
		font-size: 30px;
		font-weight:bold;
		color:@color-black;
		text-transform:uppercase;
		margin-bottom: 51px;

		@media (max-width: @screen-xs-max) {
			font-size: 20px;
			margin-bottom: 39px;
		}

		&:after {
			content: '';
			display: block;
			max-width: 41px;
			border-bottom: 2px solid @color-first;
			margin-top: 21px;
		}
	}

	.box + .box {
		margin-top:40px;
	}
}

.entryContent {
	color:@color-text-light;

	h2, h3, h4 {
		&:not(:first-child) {
			margin-top:@default-margin * 2;
		}
	}
	p, ul, ol {
		margin-bottom: @default-margin;
	}

	ul {
		.arrowList();
		margin-left: 10px;
	}

	ol {
		list-style-type: none;
		margin-left: 10px;
		padding-left: 15px;

		li {
			margin-bottom:5px;
		}

		> li {
			counter-increment: count;

			&:before {
				content: counter(count) ". ";
				display: inline-block;
				margin-left: -30px;
				min-width: 30px;
				text-align: right;
				padding-right: 5px;
				color: @color-first;
			}
		}

		ol {
			list-style-type: lower-alpha;

			li {
				margin:5px 0;
				counter-increment:none;
				&:before {
					display:none;
				}
			}
		}
	}

	a {
		text-decoration:underline;
	}
}
