.moya_form {
	div.elm_datePicker {
		.lmnt:before {
			top: 10px;
		}
		input.hasDatepicker {
			&:extend(div.elm_text .size_large);
			background: #fff;
		}
	}
	div.elm_radio {
		span.lmnt label {
			background: transparent;
		}
	}

	label {
		color: @color-text;

		&.required {
			color: @color-text;

			&:after {
				color: #cc0000;
			}
		}
	}
}

.form-control,
input[type=password],
input[type=text],
select,
textarea {
	height: auto;
}

#moya_form_fyrirspurnir {
	select,
	textarea,
	div.elm_emailAddress .size_medium,
	div.elm_emailAddress .size_large,
	div.elm_text .size_large,
	div.elm_text .size_medium {
		&:extend(div.elm_text .size_large);
		width: 100%;
		max-width: 400px;
	}
	label {
		@media (min-width:@screen-sm-min) {
			float: left;
			width: 120px;
			text-align: right;
			margin-right: 12px;
		}
	}
	.elm_controls,
	.elm_recaptcha {
		@media (min-width:@screen-sm-min) {
			margin-left: 132px;
		}
	}
}
