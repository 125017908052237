.row-same-height {
	display: table;
	width: 100%;
	table-layout:fixed;
}

.row-sm-height {
	@media (min-width: @screen-sm-min) {
		display: table;
		width: 100%;
		table-layout:fixed;
	}
}

.row-md-height {
	@media (min-width: @screen-md-min) {
		display: table;
		width: 100%;
		table-layout:fixed;
	}
}

.row-lg-height {
	@media (min-width: @screen-lg-min) {
		display: table;
		width: 100%;
		table-layout:fixed;
	}
}

.row-full-height {
	height: 100%;
}

.col-full-height {
	height: 100%;
}

/* vertical alignment styles */
.col-top {
	vertical-align: top;
}

.col-middle {
	vertical-align: middle;
}

.col-bottom {
	vertical-align: bottom;
}

/* columns of same height styles */
.col-xs-height {
	display: table-cell;
	float: none !important;
}

.col-sm-height {
	@media (min-width: @screen-sm-min) {
		display: table-cell;
		float: none !important;
	}
}

.col-md-height {
	@media (min-width: @screen-md-min) {
		display: table-cell;
		float: none !important;
	}
}

.col-lg-height {
	@media (min-width: @screen-lg-min) {
		display: table-cell;
		float: none !important;
	}
}