#header {
	.clearfix();

	// Mobile header
	@media (max-width:@screen-sm-max) {
		position:fixed;
		z-index:@zindex-header;
		top:0;
		left:0;
		width:100%;
		height:@header-height-mobile;
		background:#fff;
		border-bottom:1px solid @color-first;
		margin:0;

		body.loggedIn & {
			top:31px;
		}
	}

	.contentWrap {
		max-width: @site-width + @grid-gutter-width + 215px;

		@media (min-width:@screen-md-min) {
			display: flex;
			align-items: center;
		}
		@media (max-width:@screen-md-max) and (min-width:@screen-md-min) {
			padding-right:0;
		}
	}

	.logo {
		position: relative;
		z-index: @zindex-header-logo;

		@media (min-width:@screen-md-min) {
			flex: 0 1 auto;
			padding-right: 30px;
		}

		a {
			display:inline-block;
			padding: 10px 0;

			@media (max-width:@screen-sm-max) {
				padding: 8px 0;
				float: left;
			}

			img {
				@media (max-width:@screen-sm-max) {
					max-height: 35px;
				}
			}
		}
	}

	#mainmenu {
		@media (min-width:@screen-md-min) {
			flex: 1 1 auto;
			margin-top: 32px;
		}
	}
}

#qn {
	position: absolute;
	top: 17px;
	right: 15px;

	@media (max-width:@screen-sm-max) {
		right: 70px;
	}

	> * {
		float:left;

		&:not(:first-child) {
			margin-left:20px;
		}
	}

	.mmLevel1 {
		.clearfix();

		li {
			float:left;
			font-size:12px;
			font-weight:bold;

			&:not(:first-child) {
				margin-left:7px;

				&:before {
					content:' | ';
					padding-right: 5px;
				}
			}

			a {
				text-transform:uppercase;
				color:@color-black;

				&:hover {
					color:@color-first;
				}
			}
		}
	}
}

#divisions {
	.hidden-sm;
	.hidden-xs;

	ul {

		li {
			float:left;

			&.selected {
				display:none;
			}

			&:not(:first-child) {
				margin-left:10px;

				&:before {
					content:' | ';
					padding: 0 6px 0 0;
				}
			}
			&.selected + li:before {
				display:none;
			}

			a {
				font-size:16px;
				color:currentColor;

				&:hover {
					color:@color-first;
				}
			}

		}
	}
}
