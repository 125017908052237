a.social {
	&[href*="facebook"]:before {
		.fab();
		content: '\f39e';
	}
	&[href*="twitter"]:before {
		.fab();
		content: @fa-var-twitter;
	}
	&[href*="instagram"]:before {
		.fab();
		content: @fa-var-instagram;
	}
	&[href*="pinterest"]:before {
		.fab();
		content: @fa-var-pinterest;
	}
	&[href*="youtube"]:before {
		.fab();
		content: @fa-var-youtube;
	}
	&[href*="linkedin"] {
		.fab();
		content: '\f0e1';
	}
	&[href*="flickr"] {
		.fab();
		content: @fa-var-flickr;
	}
	&[href*="plus.google"] {
		.fab();
		content: '\f0d5';
	}
	&[href*="rss"],
	&[href*="feed"] {
		&:before {
			.fas();
			content: @fa-var-rss;
		}
	}
	&:before {
		margin-right: 5px;
	}

	#footerInfo & {
		display:inline-block;
		vertical-align:top;
		width:55px;
		height:55px;
		font-size: 0;
		border: 2px solid @color-text;
		color:@color-text;
		border-radius: 50%;
		text-align:center;
		margin: 0 6px;
		transition: background .4s ease;

		&:before {
			font-size: 22px;
			transition: 0.4s ease color;
			margin:0;
			line-height: 51px;
		}

		&:hover {
			background: @color-text;

			&:before {
				color: @color-gray;
			}
		}
	}
}
