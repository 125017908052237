.auth #main {
	background:@color-first;
	border-radius:6px;
	padding:@grid-gutter-width/2;
	max-width: 320px;
	margin: 50px auto 0;
	float: none!important;

	#auth {
		padding: 10px 0;
		margin: 0;
		min-height:0;
	}

	h1,
	label {
		color:#fff;
	}

	.boxTitle {
		margin:0;
	}

	.boxText {
		padding-bottom:20px!important;
	}
	h1 {
		font-size:38px;
		line-height:38px;
		border-bottom:0;
		margin:10px 0 20px;
		text-align:center;
	}
	.cancel {
		border-color:#f0f0f0;
		color:#fff;
		transition:all .4s ease;
		&:hover {
			background-color:#000;
		}
	}
	a {
		color:#fff;
	}
	.submit {
		background:#f0f0f0;
		color:@color-first!important;
		border-color:#f0f0f0;
		&:hover {
			background:@color-first-light;
			color:#fff!important;
		}
	}
}
