.box_newsheadlines {
	li {
		position: relative;
		padding-bottom:30px;
		border-bottom:1px solid #e5e5e5;
		margin-bottom:28px;

		@media (max-width:@screen-xs-max) {
			padding-bottom:20px;
			margin-bottom:20px;
		}

		.row {
			position: relative;

			&:hover {
				h3 {
					color:@color-first;
				}

				.image img {
					transform: perspective(1px) scale(1.04);
				}
			}
		}


		.image {
			float:left;
			padding: 0 15px;

			@media (min-width:@screen-sm-min) {
				width: 170px + 30px;
				margin-top: 3px;
			}
			@media (max-width:@screen-xs-max) {
				width: 110px + 20px;
			}

			span {
				display: block;
				overflow: hidden;
			}
			img {
				transition: transform .4s ease;
			}
		}

		.content {
			float:left;
			padding: 0 15px;

			@media (min-width:@screen-sm-min) {
				width: calc(~"100% - 200px");
			}
			@media (max-width:@screen-xs-max) {
				width: calc(~"100% - 130px");
				padding-left:5px;
			}
		}

		h3 {
			color:@color-black;
			margin-bottom: 6px;

			@media (max-width:@screen-xs-max) {
				font-size:20px;
				font-weight: normal;
				margin-bottom: 10px;
			}
		}
		.date {
			font-size:14px;
			color:@color-text-light;

			@media (min-width:@screen-sm-min) {
				margin-bottom: 11px;
			}
		}
		.intro {
			color:@color-text-light;
			.ellipsisMax(4, 18px, 1.3334);
			.hidden-xs;
		}

		a.more {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border: 0;
			background: none !important;
		}

		.jip {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}

	.entriesFooter {
		text-align:right;
		padding-top: 12px;
	}
}
