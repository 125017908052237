#mainmenuToggle {
	.visible-xs-block();
	.visible-sm-block();

	position:fixed;
	z-index:@zindex-header + 2;
	top: 0;
	right: 0;
	cursor: pointer;
	padding: 13px 15px;
	box-sizing: content-box;

	body.loggedIn & {
		top:31px;
	}

	span {
		display: block;
		width: 30px;
		height: 3px;
		background: @color-black;
		margin: 4px auto;
		transition: transform .4s ease, opacity .4s ease;

		.mainmenu-open & {
			&.first {
				transform: translateY(8px) rotate(45deg);
			}
			&.middle {
				opacity:0;
			}
			&.last {
				transform: translateY(-6px) rotate(-45deg);
			}
		}
	}
}

#mainmenu {
	position: relative;

	.mmLevel1 {
		@media (min-width:@screen-lg-min) {
			> li {
				&:not(:first-child) {
					margin-left:20px;
				}
			}
		}

		@media (min-width:@screen-md-min) {
			display: flex;
			justify-content:flex-end;
			padding-right:50px;

			> li {
				position: relative;

				> a {
					display:inline-block;
					color:currentColor;
					text-align:center;
					font-size:16px;
					font-weight:bold;
					text-transform:uppercase;
					padding:12px 10px 17px;
				}

				> a:hover,
				&.selectedLevel1 > a {
					color:@color-first;
				}

				&.children {
					> a {
						&:after {
							content:@fa-var-angle-down;
							font:normal normal normal 16px/1 FontAwesome;
							padding-left:9px;
							color:@color-first;
						}
					}
				}

				// Default behavior is to show only first level in mainmenu
				> ul {
					display:none;
				}
			}
		}
	}

	@media (min-width:@screen-md-min) {
		.ele-language,
		.expand {
			display: none;
		}
	}


}
