#breadcrumbs {
	.hidden-sm;
	.hidden-xs;
	position: relative;
	font-size:12px;
	font-weight:bold;
	text-transform:uppercase;
	background:#ebebeb;
	padding: 10px 0 11px;
	margin-bottom: 55px;

	&:before  {
		content:'';
		position: absolute;
		left:0;
		right:0;
		top:0;
		height:13px;
		#gradient > .vertical(#dedede, #ebebeb);
	}


	.forbidden & {
		display:none;
	}

	.boxText {
		&:extend(.contentWrap);
	}

	.sep {
		padding: 0 4px;
		color:@link-color;
	}

	.last {
		color:@color-text-light;
	}
}
