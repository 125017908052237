#gallery {
	h3,
	h4 {
		font-weight:600!important;

		a {
			color:@color-black;

			&:hover {
				color:@color-first;
			}
		}
	}
}
