// Generate the xxs columns
@screen-xxs: @screen-xs-min;
@screen-xxs-max: (@screen-xs-min - 1);

.make-xxs-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (max-width: @screen-xxs) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}

.contentWrap {
	position: relative;
	display: block;
	width: 100%;
	max-width: @site-width + @grid-gutter-width;
	padding-left: @grid-gutter-width / 2;
	padding-right: @grid-gutter-width / 2;
	margin: 0 auto;
}

.clearfix() {
	&:before,
	&:after {
		content: " "; // 1
		display: block; // 2
	}
	&:after {
		clear: both;
	}
}

.removeSelectArrow {
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
	}

	/*for IE10*/
	select::-ms-expand {
		display: none;
	}
}

.ellipsisMax(@lines-to-show:3; @font-size:@font-size-base; @line-height:@line-height-base) {
	display: block;
	display: -webkit-box;
	max-height: @font-size*@line-height*@lines-to-show;
	font-size: @font-size;
	line-height: @line-height;
	-webkit-line-clamp: @lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.arrowList(@color:@color-first, @arrow:@fa-var-stop,@topPosition:9px,@liPadding:15px, @iconSize:6px) {
	&:extend(ul.reset);

	li {
		position: relative;
		padding-left:@liPadding;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		.fa-before(@arrow);
		&:before {
			position: absolute;
			left: 0;
			top: @topPosition;
			color:@color;
			font-size: @iconSize!important;
		}
	}
}
