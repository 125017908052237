#news {
	h2 {
		a {
			color:@color-black;

			&:hover {
				color:@color-first;
			}
		}
	}

	.entries {
		.entry {
			margin: 37px 0;

			.entryImage {
				&:extend(.mceAlignRight);
				@media (min-width:@screen-sm-min) {
					max-width:345px;
				}
				@media (max-width:@screen-xs-max) {
					float: none;
				}
			}

			h2 {
				margin-bottom:13px;

				&:after {
					content:'';
					display:block;
					max-width:60px;
					border-bottom:1px solid @color-first;
					margin-top: 17px;
				}
			}

			.entryInfo {
				font-size:16px;
				color:@color-text;
				margin-bottom: 17px;
			}

			.entryContent {
				color:@color-text-light;
				.ellipsisMax(4);
			}
		}
	}

	.boxText > .entry {
		padding-bottom:0;
		border-bottom:0;
		margin-bottom:0;
	}

	.entry {
		.entryFooter {
			a.back {
				padding: 6px 0;
			}
			.addthis_toolbox {
				@media (min-width:@screen-xs-min) {
					float:right;
				}

				@media (max-width:@screen-xxs-max) {
					clear: both;
					padding-top: 20px;
				}
			}
		}
	}
}
