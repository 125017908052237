// The old clear
br.clear {
	clear:both;
}

// This was reset in the old reset
ul.menu {
	padding:0;
}

// Wider login form since we use padded buttons
div#auth {
	width:100%;
	max-width:320px;

	input.text {
		width:100%;
	}
}

#employees .content {
	.clearfix();
}

#boxman_sections {
	overflow:hidden;

	.boxman_template {
		width:100%!important;
		max-width:300px;
	}
}

// TINYMCE: Fixes the button padding in the toolbar when using box-sizing
.mceLayout {
	td, a, th, tr {
		-webkit-box-sizing:content-box !important;
		-moz-box-sizing:content-box !important;
		box-sizing:content-box !important;
	}
}

body#tinymce table {
	width:100%;
	table-layout: fixed;

	td {
		font-size: 1em;
		font-family: inherit;
	}
}

.menu, .toolbar, .strevda ul {
	padding:0;
}

.hidden, .hide {
	display:none;
}

.noscreen {
	display:block;
	&:extend(.sr-only);
}

.noprint {
	@media print {
		display:none!important;
	}
}

.elm_datePicker {
	.lmnt {
		position: relative;
		display:inline-block;
		.fa-before(@fa-var-calendar-o);
		&:before {
			position: absolute;
			right: 10px;
			top: 13px;
			font-size: 24px;
			color:@color-first;
		}
	}

	input.hasDatepicker {
		background:none;
	}

	.ui-datepicker-trigger {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.ui-datepicker {
	select {
		min-width: inherit;
		min-height: inherit;
		height: inherit;
		padding: inherit;
		background: inherit;
		color: inherit;
		border: inherit;
		border-radius: inherit;
		box-shadow: inherit;
		line-height: inherit;
		transition: inherit;

		option {
			color:@color-text;
		}
	}
}

.el-finder-dialog {
	input {
		min-width:0;
	}
}
.el-finder-cwd div input {
	min-width:0;
	min-height:0;
	height:auto;
	line-height:normal;
	font-size:inherit;
}

.el-finder-dialog {
	font-size:15px!important;
}


input[type=file] {
	max-width:100%;
}

//Hide calendar stuff in news
#grid-news-form th,
#grid-news-form td,
#newstabs li {
	&:nth-child(4) {
		display:none;
	}
}

/** Fix fancybox images url for gulp **/
#fancybox-loading, .fancybox-close {
	background-image: url('images/fancybox_sprite.png');
}
#fancybox-loading div {
	background: url('images/fancybox_loading.gif') center center no-repeat;
}
.fancybox-nav {
	background: transparent url('images/blank.gif'); /* helps IE */
}
.fancybox-overlay {
	background: url('images/fancybox_overlay.png');
}
#fancybox-buttons a {
	background-image: url('images/fancybox_buttons.png');
}
