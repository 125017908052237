.button,
input.submit,
button.save_design,
input[type="submit"] {
	&:extend(.btn);
	&:extend(.btn-primary);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-primary:hover);
	}
}


input.cancel,
button.cancel,
button.delete,
button#cancel {
	&:extend(.btn);
	&:extend(.btn-default);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-default:hover);
	}
}

.btn {
	transition: background .4s ease, color .4s ease;
	white-space: inherit;

	&.fa {
		font-family:@font-family-base;
		padding-left: 12px;

		&:before {
			font:normal normal normal 20px/1 FontAwesome;
			padding-right: 17px;
		}

		&.fa-angle-left {
			position: relative;
			padding-left:30px;

			&:before {
				font-size:24px;
				position: absolute;
				left: 10px;
				top: 0;
			}
		}
	}

	&+.btn {
		margin-left:10px;
	}

	&.btn-primary {
		text-transform:uppercase;
		font-size:16px;
		font-weight:500;
		padding: 11px 20px;
		text-decoration:none;

		&:hover {
			background:@color-first-dark;
		}
		&:focus {
			outline-color: @color-first-dark!important;
		}
	}

	&.btn-secondary {
		color:#fff!important;
		background:@color-second;

		&:hover {
			background:@color-second-dark;
		}
	}

	&.btn-border {
		border:1px solid @color-first;
		text-transform:uppercase;
		font-size:16px;
		font-weight:600;
		padding: 8px 20px;

		&:hover {
			background:@color-first;
			color:@color-white;
		}
	}
}
