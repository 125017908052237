#header {
	.logo {
		a {
			@media (min-width:@screen-md-min) {
				padding:20px 20px 20px 0;
			}

			svg {
				height: 100px;
				width: auto;
				@media (max-width:@screen-sm-max) {
					width: 42px;
					height: 40px;
				}
			}

			span {
				position: absolute;
				color: @color-black;
				text-transform: uppercase;
				font-weight: bold;
				letter-spacing: 3.6px;

				@media (min-width:@screen-md-min) {
					font-size: 20px;
					left: 106px;
					top: 68px;
					width: 320px;
				}
				@media (max-width:@screen-sm-max) {
					font-size: 14px;
					top: 14px;
					left: 51px;
				}
			}
		}
	}
}
