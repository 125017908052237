#footerInfo {
	position: relative;
	border-top:1px solid #cfcfcf;
	background:@color-gray;
	margin-bottom: -2%;
	text-align:center;
	padding: 64px 0 40px;

	@media (max-width:@screen-xs-max) {
		padding-bottom:20px;
	}

	.contentWrap {
		min-height: 80px;
	}

	a {
		color: @color-first;

		&:hover {
			color: @color-black;
		}
	}

	.jip {
		position: absolute;
		top: 10px;
		right: 10px;

		&.list {
			background: none;
			border: 0;
			width: 24px;
			height: 24px;
			overflow: hidden;
		}
	}

	.box {
		.make-xs-column(12);
		margin-bottom:20px;

		&:first-child:last-child {
			text-align:center;
		}
		&:first-of-type:nth-last-of-type(2),
		&:first-of-type:nth-last-of-type(2) ~ .box {
			.make-sm-column(6);
		}
		&:first-of-type:nth-last-of-type(3),
		&:first-of-type:nth-last-of-type(3) ~ .box {
			.make-md-column(4);
		}
		&:first-of-type:nth-last-of-type(4),
		&:first-of-type:nth-last-of-type(4) ~ .box {
			.make-sm-column(6);
			.make-sm-column(6);
			.make-md-column(3);
		}
	}

	.box_pagecontent {
		.boxTitle span {
			display: block;
			margin-bottom: 12px;
		}

		ul {
			&:extend(ul.reset);

			li {
				@media (min-width: @screen-md-min) {
					display:inline-block;
					vertical-align:top;
				}
			}
			&:first-of-type {
				margin-bottom: 32px;

				li {
					@media (min-width:@screen-md-min) {
						&:not(:last-child) {
							&:after {
								content:' / ';
								padding: 0 7px;
							}
						}
					}
				}
			}
			&:nth-of-type(2) {
				li {
					padding: 0 15px;
				}
			}
		}
	}
}
