*:active, *:focus {
	body:not(.tabbing) & {
		outline: none!important;
	}
	body.tabbing & {
		outline-width:2px!important;
		outline-style:solid!important;
		outline-color:@color-second;
		outline-offset: -2px;
	}
}

#skipToMain {
	position: absolute;
	left: 0;
	top: 0;
	background: @color-first;
	color: @color-white;
	z-index: 99;
	padding: 8px 15px 9px;
	font-weight: bold;
	display:none;
	opacity:0;
	transition:opacity .4s ease;
	outline-color: @color-white;

	.tabbing & {
		display:block;
	}

	&:focus {
		opacity:1;
	}

	.loggedIn & {
		top:31px;
	}
}
